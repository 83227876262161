import React from 'react';
import tw from 'twin.macro';
import { HEADERS, URLS } from '../../../constants/common';
import logoColor from '../../../images/logo-color.png';

const Container = tw.div`relative bg-htgGreen-veryDark -mb-8 px-8`;

const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-center md:justify-between`;

const Column = tw.div`mx-8 md:mx-0 md:w-1/5`;
const WideColumn = tw(Column)`text-center md:ml-4 md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold text-htgGreen-light`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent text-htgGreen-light hover:border-htgGreen pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`h-16`;
const BottomText = tw.div`text-htgGreen pb-4 text-center w-full`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 text-htgGreen`;

const Footer = () => (
  <Container>
    <FiveColumns>
      <WideColumn>
        <LogoContainer>
          <LogoImg src={logoColor} />
        </LogoContainer>
        <CompanyDescription>
          Hacking the grow aims to educate the next generation of cannabis growers, using well designed research in an easy to understand manner.
        </CompanyDescription>
      </WideColumn>
      <Column>
        <ColumnHeading>
          Quick Links
        </ColumnHeading>
        <LinkList>
          {Object.keys(HEADERS).map(headerKey => (
            <LinkListItem>
              <Link href={HEADERS[headerKey]}>
                {headerKey}
              </Link>
            </LinkListItem>
          ))}
        </LinkList>
      </Column>
      <Column>
        <ColumnHeading>
          Legal
        </ColumnHeading>
        <LinkList>
            <LinkListItem>
              <Link href={URLS['privPolicy']}>Privacy Policy</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={URLS['cookiePolicy']}>Cookie Policy</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={URLS['disclosures']}>Disclosures</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={URLS['contact']}>Contact</Link>
            </LinkListItem>
          </LinkList>
      </Column>
    </FiveColumns>
    <BottomText>
      &copy; Copyright 2021, Hacking the Grow All Rights Reserved.
    </BottomText>
  </Container>
);

export default Footer;
