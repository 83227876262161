import { Link } from 'gatsby';
import React from 'react';
import logoColor from '../../images/logo-color.png';
import { HEADERS } from '../../constants/common';

const Header = () => {
  const [burgerState, setBurgerState] = React.useState(false);

  return (
    <nav role="navigation" aria-label="main navigation">
      <div className="px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div
            className="absolute inset-y-0 left-0 flex items-center sm:hidden"
            onClick={() => setBurgerState(!burgerState)}
            onKeyPress={() => setBurgerState(!burgerState)}
            role="button"
            tabIndex={0}
          >
            {burgerState ? (
              <svg
                className="h-7 w-7"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-7 w-7"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            )}
          </div>
          <div className="flex-1 flex items-center justify-center sm:item-stretch sm:justify-end">
            <div className="flex-shrink-0 flex items-center sm:mr-auto">
              <Link to="/">
                <img
                  className="h-10 lg:h-12 xl:mt-4 w-auto"
                  src={logoColor}
                  alt="Hacking the Grow"
                />
              </Link>
            </div>
            <div className="hidden sm:block sm:ml-6 xl:mt-4">
              {Object.keys(HEADERS).map(headerKey => (
                <Link
                  to={HEADERS[headerKey]}
                  className="hover:bg-htgGreen-light px-3 lg:px-6 py-2 rounded-md text-sm lg:text-base xl:text-xl"
                >
                  {headerKey}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      {burgerState ? (
        <div className="sm:hidden block">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <div className="bg-gray-200 px-3 py-2 rounded-md text-base font-medium flex flex-col space-y-2">
              {Object.keys(HEADERS).map(headerKey => (
                <Link
                  to={HEADERS[headerKey]}
                  className="hover:bg-htgGreen-light bg-gray-300 px-3 py-2 rounded-md text-sm text-center"
                >
                  {headerKey}
                </Link>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </nav>
  );
};

export default Header;
