/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import LogRocket from 'logrocket';
import Footer from './footer/footer';
import Header from './header';
import { GlobalStyles } from 'twin.macro'
import '../layout.scss';

LogRocket.init('s9u5rv/dev-hackingthegrowcom');

const Layout = ({ children }) => {
  return (
    <body className="relative min-h-screen">
      <GlobalStyles />
      <Header />
      <div className="pb-48 h-full lg:container lg:mx-auto px-6">
        {children}
      </div>
      <Footer />
    </body>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
