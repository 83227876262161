/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({
  description,
  title,
  date,
  imagePublicUrl,
  path,
  type,
  keywords,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            keywords
          }
        }
      }
    `
  );

  const seo = {
    metaDescription: description || site.siteMetadata.description,
    metaTitle: title || site.siteMetadata.title,
    image: `${site.siteMetadata.siteUrl}${imagePublicUrl}`,
    url: `${site.siteMetadata.siteUrl}${path}`,
    date: date,
    type: type || "website",
    metaKeywords: keywords || site.siteMetadata.keywords,
  };

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={seo.metaTitle}
      meta={[
        {
          name: `description`,
          content: seo.metaDescription,
        },
        {
          name: `image`,
          content: seo.image,
        },
        {
          name: `keywords`,
          content: seo.metaKeywords,
        },
        {
          property: `og:url`,
          content: seo.url,
        },
        {
          property: `og:type`,
          content: seo.type,
        },
        {
          property: `og:title`,
          content: seo.metaTitle,
        },
        {
          property: `og:description`,
          content: seo.metaDescription,
        },
        {
          property: `og:image`,
          content: seo.image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:site`,
          content: seo.url,
        },
        {
          name: `twitter:creator`,
          content: `Hacking the Grow`,
        },
        {
          name: `twitter:title`,
          content: seo.metaTitle,
        },
        {
          name: `twitter:description`,
          content: seo.metaDescription,
        },
        {
          name: `twitter:image`,
          content: seo.image,
        },
      ]}
    >
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org/",
          "@type": "WebSite",
          name: "Hacking the Grow",
          url: "https://hackingthegrow.com",
        })}
      </script>
    </Helmet>
  );
}

SEO.propTypes = {
  path: PropTypes.string,
  imagePublicUrl: PropTypes.string,
  date: PropTypes.string,
}

SEO.defaultProps = {
  path: "",
  imagePublicUrl: "",
  date: ""
}

export default SEO;
