export const BACKGROUND_COLOR = "#E0E0E0";
export const HEADER_COLOR = "#3A606E";
export const HEADER_TEXT_COLOR = BACKGROUND_COLOR;
export const HEADERS = {
  Home: "/",
  Growing: "/growing/",
  Contact: "/contact/",
};
export const URLS = {
  home: "/",
  growing: "/growing/",
  contact: "/contact/",
  bestCoco: "/best-coco-coir-nutrients/",
  bestHydro: "/best-hydro-nutrients/",
  hydro: "/hydro/",
  germination: "/marijuana-germination/",
  seedling: "/seedling/",
  privPolicy: "/privacy-policy/",
  cookiePolicy: "/cookie-policy/",
  disclosures: "/disclosure/",
  contact: "/contact/"
};
export const CONTACT_ENDPOINT =
  "https://0k5kqsao2k.execute-api.us-east-1.amazonaws.com/Prod/contact/";
export const G_RECAPTCHA_PUBLIC_KEY =
  "6LexJzMaAAAAAMDKDQzjeyAEyWvuf7SL4VhsbN3W";
